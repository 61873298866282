export const routePaths = {
  root: "/",
  auth: "/auth",
  login: "/auth/login",
  home: "/home",
  location: "/home/location",
  locationDate: "/home/location/date",
  locationCategories: "/home/location/list",
  locationCategoriesArticles: "/home/location/list/:id_cat",
  locationSubCategories: "/home/location/list/:id_cat/subCat",
  locationSubCategoriesArticles:
    "/home/location/list/:id_cat/subCat/:id_subCat",
  locationCollect: "/home/location/collect",
  locationConfirmCommand: "/home/location/confirm-command",
  locationDetails: "/home/location/details/:id_command",
  locationReturnCaution: "/home/location/return-caution",
  admin: "/home/admin",
  adminUsers: "/home/admin/users",
  adminCategories: "/home/admin/categories",
  adminSubCategories: "/home/admin/categories/:id_cat/subCat",
  adminArticles: "/home/admin/categories/:id_cat/subCat/:id_subCat",
  statistics: "/home/statistics",
};
