import { Icon } from "@iconify/react";
import { FC } from "react";
import styled from "styled-components";

type KPICardUICProps = {
  icon: string;
  value: number;
  title: string;
  color: "red" | "green" | "yellow" | "blue" | "orange" | "olive";
};
const KPICardUIC: FC<KPICardUICProps> = ({ color, icon, title, value }) => {
  return (
    <KPIContainer color={color}>
      <KPIHeader>
        <KPISymbol color={color}>
          <Icon icon={icon} color={`var(--ui-${color}-dark)`} fontSize={24} />
        </KPISymbol>
        <KPIValue color={color}>{value}</KPIValue>
      </KPIHeader>
      <KPIMainData>
        <KPITitle color={color}>{title}</KPITitle>
      </KPIMainData>
    </KPIContainer>
  );
};

export default KPICardUIC;

const KPIContainer = styled.div<{
  color: string;
}>`
  width: 150px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 8px;
  background-color: rgba(var(--ui-${({ color }) => color}-rgb), 0.2);
  border-radius: 12px;
`;

const KPIHeader = styled.div`
  width: 100%;
  padding: 0 12px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const KPISymbol = styled.div<{
  color: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  color: var(--ui-${({ color }) => color}-dark);
  background: linear-gradient(
    135deg,
    rgba(var(--ui-${({ color }) => color}-rgb-dark), 0) 0%,
    rgba(var(--ui-${({ color }) => color}-rgb-dark), 0.24) 100%
  );
`;

const KPIMainData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
`;

const KPIValue = styled.span<{
  color: string;
}>`
  font-size: 28px;
  font-weight: 600;
  color: var(--ui-${({ color }) => color}-dark);
`;

const KPITitle = styled.span<{
  color: string;
}>`
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  color: var(--ui-${({ color }) => color}-dark);
`;
