import "./ReturnCaution.page.styles.scss";

import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

import { Icon } from "@iconify/react";

import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import Button from "@/components/UICs/Button/Button.uic";

import { useAppDispatch } from "@/store";
import { clearSelectedCommand } from "@/store/reducers/items/items.reducer";

import { routePaths } from "@/config";
import { selectItemsSelectedCommand } from "@/store/reducers/items/items.selector";
import { TCommand } from "@/types/command";
import { formatPriceOnDisplay } from "@/utils";
import { APIreturnCaution } from "@/features/Dashboard/api/command.api";
import { ToastError, ToastSuccess } from "@/utils/toast";

const PReturnCaution: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedCommand = useSelector(selectItemsSelectedCommand);

  const [loading, setLoading] = useState(false);
  // const [onAction, setOnAction] = useState(false);

  const onReturn = () => {
    // if (onAction) {
    //   Swal.fire({
    //     title: "Voulez vous abandonnez le retour de caution ?",
    //     showCancelButton: true,
    //     cancelButtonText: "Annuler",
    //     confirmButtonText: "Confirmer",
    //     confirmButtonColor: "var(--ui-primary)",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       navigate(routePaths.location);
    //       dispatch(clearSelectedCommand());
    //     }
    //   });
    // } else {
    navigate(routePaths.location);
    dispatch(clearSelectedCommand());
    // }
  };

  if (selectedCommand === null) {
    navigate(routePaths.location);
    return <></>;
  }

  const allCautionValidation = () => {
    Swal.fire({
      icon: "warning",
      title: "Clôture de la commande",
      text: "Vous confirmez votre choix afin de clôturer cette commande ?",
      showCancelButton: true,
      cancelButtonText: "Non, Annuler",
      confirmButtonText: "Oui, Cloturer",
      confirmButtonColor: "var(--ui-primary)",
    }).then((result) => {
      if (result.isConfirmed) {
        APIreturnCaution((selectedCommand as TCommand).codeCommande)
          .then((res) => {
            if (res.error) {
              ToastError.fire({ text: res.message });
            } else {
              ToastSuccess.fire({
                text: "La commande est terminée.",
                timer: 10000,
              });
              navigate(routePaths.location);
            }
          })
          .catch((reason) => {
            if (reason.response.status === 400) {
              ToastError.fire({
                text: reason.response.data.message,
                timer: 6000,
              });
            } else {
              ToastError.fire();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const cautionToReturn = (selectedCommand as TCommand).montantCautionRendue;

  return (
    <div className="page p-return-caution">
      <h3 className="page__title">
        <IconButton
          aria-label="back-drop"
          color="primary"
          size="medium"
          onClick={onReturn}
          sx={{ width: "fit-content" }}
        >
          <Icon icon="akar-icons:arrow-left" fontSize={20} />
        </IconButton>
        {/* Récupération des articles */}
        Terminer la commande
      </h3>

      {selectedCommand && (
        <div className="collect-pre-step">
          <div className="command-details">
            <h5>Détails sur la commande</h5>
            <div className="content">
              <span className="data">
                <strong>Code : </strong>
                {(selectedCommand as TCommand).codeCommande}
              </span>
              <h6>Client</h6>
              <div className="sub-content">
                <span className="data">
                  <strong>Nom : </strong>
                  {(selectedCommand as TCommand).client.nom_prenom}
                </span>
                <span className="data">
                  <strong>Email : </strong>
                  {(selectedCommand as TCommand).client.email}
                </span>
                <span className="data">
                  <strong>Téléphone : </strong>
                  {(selectedCommand as TCommand).client.telephone}
                </span>
              </div>
              <h6>Date</h6>
              <div className="sub-content">
                <span className="data">
                  <strong>Début : </strong>
                  {new Date(
                    (selectedCommand as TCommand).dateDebut
                  ).toLocaleDateString("fr", { dateStyle: "full" })}{" "}
                  à{" "}
                  {new Date(
                    (selectedCommand as TCommand).dateDebut
                  ).toLocaleTimeString("fr", { timeStyle: "medium" })}
                </span>
                <span className="data">
                  <strong>Fin : </strong>
                  {new Date(
                    (selectedCommand as TCommand).dateFin
                  ).toLocaleDateString("fr", { dateStyle: "full" })}{" "}
                  à{" "}
                  {new Date(
                    (selectedCommand as TCommand).dateFin
                  ).toLocaleTimeString("fr", { timeStyle: "medium" })}
                </span>
              </div>
              <h6>Montant</h6>
              <div className="sub-content">
                <span className="data">
                  <strong>Devis : </strong>
                  {formatPriceOnDisplay(
                    (selectedCommand as TCommand).montantDevis
                  )}{" "}
                  FCFA
                </span>
                <span className="data">
                  <strong>Caution payée: </strong>
                  {formatPriceOnDisplay(
                    (selectedCommand as TCommand).montantCaution
                  )}{" "}
                  FCFA{" "}
                </span>
                <span className="data">
                  <strong className="highlight">
                    {cautionToReturn < 0
                      ? "Pénalité à recouvrer :"
                      : "Caution à retourner :"}
                  </strong>
                  <span className="highlight">
                    {formatPriceOnDisplay(
                      cautionToReturn < 0 ? -cautionToReturn : cautionToReturn
                    )}{" "}
                    FCFA{" "}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="return-caution-frame">
            <p>
              {cautionToReturn < 0
                ? "Avez-vous recouvré la pénalité :"
                : "Avez-vous retourné la caution :"}{" "}
              <HelpOutlineIcon color="info" fontSize="large" />
            </p>
            <div className="action">
              <Button
                type="button"
                label="Oui"
                onClick={() => {
                  allCautionValidation();
                }}
                isLoading={loading}
              />
              {/* <Button
                type="button"
                color="var(--ui-warning)"
                label="Non"
                onClick={() => {
                  setOnAction(true);
                }}
              /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PReturnCaution;
