import "./CollectArticle.form.styles.scss";

import { FC } from "react";
import { FormikHelpers, getIn, useFormik } from "formik";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import FormErrorMessage from "@/components/UICs/Forms/FormErrorMessage/FormErrorMessage.iuc";

import { TCollectFormValues } from "../../Containers/CollectModal/CollectModal.container";

const ErrorMessage = WithFormikErrorMessage(FormErrorMessage);

type FCollectArticleProps = {
  deliveredArticles: number;
  initialValues: TCollectFormValues;
  isLastStep: () => boolean;
  handleSubmit: (
    values: TCollectFormValues,
    formikHelpers: FormikHelpers<TCollectFormValues>
  ) => Promise<void> | void;
  handleBack: () => void;
};
const FCollectArticle: FC<FCollectArticleProps> = ({
  handleSubmit,
  handleBack,
  isLastStep,
  initialValues,
  deliveredArticles,
}) => {
  const validationSchema: any = Yup.object({
    qte_retour: Yup.number()
      .max(
        deliveredArticles,
        "La quantité retournée ne peut pas dépasser la quantité louée"
      )
      .min(0, "Pas de valeurs négatives")
      .required("Cette information est importante"),
    qte_damaged: Yup.number()
      .min(0, "Pas de valeurs négatives")
      .required("Cette information est importante")
      .test({
        name: "limite_dommagée",
        message:
          "La quantité endommagée ne peut pas dépasser la quantité retournée",
        test: function (value) {
          const { qte_retour } = this.parent;
          if (value > qte_retour) {
            return false;
          } else {
            return true;
          }
        },
      }),
    cout_unitaire_dommage: Yup.number().min(0, "Pas de valeurs négatives"),
    cout_materiel_egaree: Yup.number().min(0, "Pas de valeurs négatives"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const fieldStatus: { [key: string]: { error: string; touched: boolean } } = {
    qte_retour: {
      error: getIn(formik.errors, "qte_retour"),
      touched: getIn(formik.touched, "qte_retour"),
    },
    qte_damaged: {
      error: getIn(formik.errors, "qte_damaged"),
      touched: getIn(formik.touched, "qte_damaged"),
    },
    cout_unitaire_dommage: {
      error: getIn(formik.errors, "cout_unitaire_dommage"),
      touched: getIn(formik.touched, "cout_unitaire_dommage"),
    },
    cout_materiel_egaree: {
      error: getIn(formik.errors, "cout_materiel_egaree"),
      touched: getIn(formik.touched, "cout_materiel_egaree"),
    },
  };

  const count_egaree = deliveredArticles - formik.values.qte_retour;

  return (
    <form
      className="f-collect-article"
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-data">
        <span className="label">Nombre d’articles loués :</span>
        <strong>{deliveredArticles}</strong>
      </div>
      <div className="form-group">
        <div>
          <label htmlFor="retrieve">Nombre d’articles retournés :</label>
          <FormControl
            size="small"
            variant="outlined"
            // error={!!name.error && name.touched}
          >
            {/* <InputLabel htmlFor="retrieve">
            Nombre d’articles retournés :{" "}
          </InputLabel> */}
            <OutlinedInput
              id="retrieve"
              type="number"
              // label="Nombre d’articles retournés : "
              name="qte_retour"
              value={formik.values.qte_retour}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={
                !!fieldStatus.qte_retour.error && fieldStatus.qte_retour.touched
              }
            />
          </FormControl>
        </div>
        <ErrorMessage
          errors={formik.errors}
          touched={formik.touched}
          name="qte_retour"
        />
      </div>

      <div className="form-group">
        <div>
          <label htmlFor="damaged">Nombre d’articles endommagés :</label>
          <FormControl
            size="small"
            variant="outlined"
            // error={!!name.error && name.touched}
          >
            {/* <InputLabel htmlFor="damaged">
            Nombre d’articles endommagés :{" "}
          </InputLabel> */}
            <OutlinedInput
              id="damaged"
              type="number"
              // label="Nombre d’articles endommagés : "
              name="qte_damaged"
              value={formik.values.qte_damaged}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={
                !!fieldStatus.qte_damaged.error &&
                fieldStatus.qte_damaged.touched
              }
            />
          </FormControl>
        </div>
        <ErrorMessage
          errors={formik.errors}
          touched={formik.touched}
          name="qte_damaged"
        />
      </div>

      {formik.values.qte_damaged !== 0 && (
        <div className="form-group">
          <div>
            <label htmlFor="damaged">
              Coût unitaire article endommagé (FCFA):
            </label>
            <FormControl
              size="small"
              variant="outlined"
              // error={!!name.error && name.touched}
            >
              {/* <InputLabel htmlFor="damaged">
            Nombre d’articles endommagés :{" "}
          </InputLabel> */}
              <OutlinedInput
                id="damaged-price"
                type="number"
                // label="Nombre d’articles endommagés : "
                name="cout_unitaire_dommage"
                value={formik.values.cout_unitaire_dommage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={
                  !!fieldStatus.cout_unitaire_dommage.error &&
                  fieldStatus.cout_unitaire_dommage.touched
                }
              />
            </FormControl>
          </div>
          <ErrorMessage
            errors={formik.errors}
            touched={formik.touched}
            name="cout_unitaire_dommage"
          />
        </div>
      )}

      {count_egaree > 0 && (
        <div className="form-group">
          <div>
            <label htmlFor="damaged">Coût unitaire article égaré (FCFA):</label>
            <FormControl
              size="small"
              variant="outlined"
              // error={!!name.error && name.touched}
            >
              {/* <InputLabel htmlFor="damaged">
            Nombre d’articles endommagés :{" "}
          </InputLabel> */}
              <OutlinedInput
                id="damaged-price"
                type="number"
                // label="Nombre d’articles endommagés : "
                name="cout_materiel_egaree"
                value={formik.values.cout_materiel_egaree}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={
                  !!fieldStatus.cout_materiel_egaree.error &&
                  fieldStatus.cout_materiel_egaree.touched
                }
              />
            </FormControl>
          </div>
          <ErrorMessage
            errors={formik.errors}
            touched={formik.touched}
            name="cout_unitaire_dommage"
          />
        </div>
      )}

      <div className="button-group">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          sx={{ color: "white" }}
          disabled={!formik.isValid}
        >
          {"Enregistrer"}
        </Button>
        <Button
          variant="text"
          color="primary"
          disabled={isLastStep()}
          onClick={handleBack}
        >
          Précédent
        </Button>
      </div>
    </form>
  );
};

export default FCollectArticle;
