import { FC } from "react";
import styled, { css } from "styled-components";

interface PulseLoaderProps {
  color?: string;
}
const PulseLoader: FC<PulseLoaderProps> = ({ color = "" }) => {
  return (
    <PulseSVG
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <PulseRect color={color} x="17.5" y="15" width="15" height="70">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="-2.5;15;15"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.2s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="105;70;70"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.2s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
      </PulseRect>
      <PulseRect color={color} x="42.5" y="15" width="15" height="70">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="1.875;15;15"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.1s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="96.25;70;70"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.1s"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
      </PulseRect>
      <PulseRect color={color} x="67.5" y="15" width="15" height="70">
        <animate
          attributeName="y"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="1.875;15;15"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
        <animate
          attributeName="height"
          repeatCount="indefinite"
          dur="1s"
          calcMode="spline"
          keyTimes="0;0.5;1"
          values="96.25;70;70"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1"
          style={{ animationPlayState: "running", animationDelay: "0s" }}
        ></animate>
      </PulseRect>
      {/* <!-- [ldio] generated by https://loading.io/ --> */}
    </PulseSVG>
  );
};

export default PulseLoader;
// ======================================================================
// -- LES STYLES --
// ======================================================================

const rectAnimation = css`
  animation-play-state: running;
  animation-delay: 0s;
`;

const PulseSVG = styled.svg`
  margin: auto;
  background: none;
  display: block;
  shape-rendering: auto;
  animation-play-state: running;
  animation-delay: 0s;
`;

const PulseRect = styled.rect<{
  color: string;
}>`
  ${rectAnimation}
  fill: ${({ color }) => (color ? color : "var(--ui-primary)")};
`;
